import ThemeProvider from 'react-bootstrap/ThemeProvider';

import { AuthProvider } from '@hooks/contexts/auth-context';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

import { ModalProvider } from '@hooks/contexts/modal-context';

// import { SocketProvider } from '@hooks/contexts/socket-context';
import { ToastContainer } from 'react-toastify';

import { TelegramProvider } from '@hooks/contexts/telegram-context';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >

            <TelegramProvider>
                <AuthProvider>
                    {/* <SocketProvider> */}
                    <BrowserRouter>

                        <ModalProvider>
                            <Routes />
                        </ModalProvider>

                        <ToastContainer />

                    </BrowserRouter>
                    {/* </SocketProvider> */}
                </AuthProvider>
            </TelegramProvider>

        </ThemeProvider>
    );
}

export default App;
