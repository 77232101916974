import { useEffect, useRef } from 'react';

import Leaf1 from '@assets/img/leaf-01.png';
import Leaf2 from '@assets/img/leaf-02.png';

const leafImages = [Leaf1, Leaf2];
const maxLeaves = 10;
const createInterval = 3500;

export function LeafAnimation() {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const containerWidth = container.clientWidth || window.innerWidth;
        const containerHeight = container.clientHeight || window.innerHeight;

        function createLeaf() {
            if(container) {
                if (container.querySelectorAll('.leaf').length >= maxLeaves) return;
            }

            const leaf = document.createElement('img');
            leaf.src = leafImages[Math.floor(Math.random() * leafImages.length)];
            leaf.className = 'leaf';
            leaf.style.position = 'absolute';
            leaf.style.zIndex = '3';

            const startLeft = Math.random() * containerWidth;
            const startRotate = Math.random() * 360;
            leaf.style.left = `${startLeft}px`;
            leaf.style.top = '-50px';
            leaf.style.transform = `rotate(${startRotate}deg)`;

            if(container) {
                container.appendChild(leaf);
            }

            animateLeaf(leaf, startLeft, containerHeight);
        }

        function animateLeaf(leaf: HTMLImageElement, startLeft: number, endPosition: number) {
            const duration = Math.random() * 10000 + 10000;
            const rotateDegree = Math.random() * 360;
            const amplitude = 80;

            const startTime = performance.now();

            function update(time: number) {
                const elapsed = time - startTime;
                const progress = Math.min(elapsed / duration, 1);

                const currentTop = -50 + progress * (endPosition + 50);
                const oscillation = amplitude * Math.sin(progress * Math.PI * 2 * 2);
                const currentLeft = startLeft + oscillation;

                leaf.style.top = `${currentTop}px`;
                leaf.style.left = `${currentLeft}px`;
                leaf.style.transform = `rotate(${rotateDegree * progress}deg)`;

                if (progress < 1) {
                    requestAnimationFrame(update);
                } else {
                    leaf.remove();
                }
            }

            requestAnimationFrame(update);
        }

        function randomLeafInterval() {
            if(container) {
                if (container.querySelectorAll('.leaf').length < maxLeaves) {
                    createLeaf();
                }
            }
            setTimeout(randomLeafInterval, createInterval);
        }

        randomLeafInterval();

        return () => {
            container.querySelectorAll('.leaf').forEach(leaf => leaf.remove());
        };
    }, []);

    return <div className="content" ref={containerRef} />;
}

export default LeafAnimation;
