/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { ICustomerDTO } from '@hooks/dtos/user-dto';
import { ZuAuthStore } from '@hooks/stores/zustand-auth-store';
import { ZuToastifyStore } from '@hooks/stores/zustand-toastify-store';
import { api } from '@service/api';
import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
} from 'react';

interface ISignInProps {
    userName: string;
    password: string;
}

interface IRegisterProps {
    gender?: number,
    userName: string,
    name: string,
    password: string,
    passwordConfirm: string,
    email: string,
    emailConfirm: string,
    cellPhone: string,
    termsCheck: string,
    birth?: string,
    language: string,
    document?: string,
    referral?: number
}

interface AuthContextData {
    signIn(data: ISignInProps): Promise<void>
    createCustomer(data: IRegisterProps): Promise<void>
    signOut: () => void
    getUserProfile: () => Promise<void>
    getCustomerBalance: () => Promise<void>
    customer: ICustomerDTO | undefined
}

interface AuthProviderProps {
    children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {

    const {
        customer,
        actions: { setCustomer, setBalance, checkIfHaveSession },
    } = ZuAuthStore()

    const {
        actions: { pushToastify }
    } = ZuToastifyStore()

    function clearHash() {
        if (window.history.replaceState) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }

    async function openSession() {
        try {
            await api.get('/customer/session/open')
        } catch (error) {
            // console.log('error auth: ', error)
        }
    }

    async function getSessionInfo() {
        try {
            const response = await api.get('/customer/session/info')

            return response
        } catch (err) {
            //
        }
    }

    async function signIn(data: ISignInProps) {
        try {
            const response = await api.post('/customer/session/sign-in', {
                userName: data.userName,
                password: data.password,
            })

            const { customer, customerInfo } = response.data;

            setCustomer({ customer, customerInfo })

            pushToastify({
                message: 'Login realizado com sucesso!',
                type: 'success',
                theme: 'dark',
            });

            clearHash();
            getCustomerBalance()

        } catch (error: any) {

            pushToastify({
                message: error?.response?.data?.message,
                type: 'error',
                theme: "dark",
            });

        }
    }

    async function createCustomer(data: IRegisterProps) {

        try {
            const response = await api.post('/customer/create', {

                customer: {
                    gender: data.gender,
                    userName: data.email,
                    name: data.name,
                    password: data.password,
                    passwordConfirm: data.passwordConfirm,
                    email: data.email,
                    emailConfirm: data.email,
                    cellPhone: data.cellPhone,
                    termsCheck: data.termsCheck ? 'y' : 'n',
                    birth: data.birth,
                    language: "pt-br",
                    referral: data.referral,
                    document: data.document,
                    documentType: 1,
                }
            })

            // Logando o usuário após o seu cadastro
            signIn({ userName: data.email, password: data.password });

        } catch (error: any) {

            pushToastify({
                message: error?.response?.data?.message,
                type: 'error',
                theme: "dark",
            });

        }
    }

    async function getUserProfile() {
        try {
            const response = await api.get('/customer/info')
            const customerData = { ...customer, ...response.data.customer }

            if (customerData?.photos?.photos) {
                customerData.photos.photos = [...customerData?.photos?.photos].sort(
                    (a, b) => {
                        return a?.position - b?.position
                    },
                )
            }

            setCustomer(customerData);

        } catch (error: any) {
            console.error('Erro ao resgatar informações do perfil do usuário: ', error);
        }
    }

    async function getCustomerBalance() {
        try {
            const response = await api.get('/cash/balance')
            setBalance(response.data)
        } catch (error: any) {
            pushToastify({
                message: error?.response?.data?.message,
                type: 'error',
                theme: "dark",
            });
        }
    }

    async function signOut() {
        setCustomer(undefined);
        localStorage.removeItem('@yolo:customer');
        openSession();

        clearHash();

    }

    useEffect(() => {

        const responseInterceptor = api.interceptors.response.use(
            async (response) => {
                return response
            },
            async (error) => {
                const config = error.config
                const errorCode = String(error?.response?.data?.errorCode || '')
                if (errorCode == 'CSRFX004' || errorCode == 'CSRFX005') {
                    if (config && config.retryCount < 1) {
                        config.retryCount += 1
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                        const session = await getSessionInfo()

                        config.headers['token-id'] = session?.data.id
                        config.headers['token-csrf'] = session?.data.csrf

                        if (session?.data.id) {
                            localStorage.setItem(
                                '@yolo:token-id',
                                String(session?.data.id),
                            )
                        }
                        if (session?.data.csrf) {
                            localStorage.setItem(
                                '@yolo:token-csrf',
                                String(session?.data.csrf),
                            )
                        }

                        return api(config)
                    }
                } else if (errorCode.match('CSRFX')) {
                    signOut()
                    await openSession()
                }
                return Promise.reject(error)
            },
        )

        return () => {
            api.interceptors.response.eject(responseInterceptor)
        }
    }, [])

    useEffect(() => {
        checkIfHaveSession()
    }, [])

    useEffect(() => {
        if (customer?.customer?.id) {
            getCustomerBalance()
        }
    }, [customer])

    return (
        <AuthContext.Provider
            value={{
                signIn,
                signOut,
                createCustomer,
                getUserProfile,
                customer,
                getCustomerBalance,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)
