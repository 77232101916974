import { IBalanceCashCustomerDTO } from '@hooks/dtos/cash-dto';
import { create } from 'zustand';
import { ICustomerDTO } from '../dtos/user-dto';

type IActionsProps = {
  setCustomer: (customer: ICustomerDTO | undefined) => void;
  setBalance: (balance: { [x: string]: IBalanceCashCustomerDTO }, destructure?: boolean) => void;
  checkIfHaveSession: () => Promise<void>;
};

type IStoreProps = {
  customer: ICustomerDTO | undefined;
  balance: { [x: string]: IBalanceCashCustomerDTO };
  actions: IActionsProps;
};

export const ZuAuthStore = create<IStoreProps>((set) => ({
  customer: undefined,
  balance: {},
  actions: {
    setCustomer: (value) => {
      localStorage.setItem('@yolo:customer', JSON.stringify(value));
      return set(() => ({ customer: value }));
    },
    setBalance: (value, destructure) => {
      localStorage.setItem('@yolo:customer-balance', JSON.stringify(value));
      return set(({ balance }) => ({ balance: destructure ? { ...balance, ...value } : value }));
    },
    checkIfHaveSession: async () => {
      const data = localStorage.getItem('@yolo:customer');
      return set(() => ({ customer: data ? JSON.parse(data) : undefined }));
    },
  },
}));
