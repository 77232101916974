import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

import EggmonLogo from '@assets/img/eggmon-logo.png';

// import ambience from '@assets/audio/ambience.wav';

import LeafAnimation from "@components/leaf-animation/LeafAnimation";

import egg1 from '@assets/img/egg-1.png';
import egg2 from '@assets/img/egg-2.png';
import egg3 from '@assets/img/egg-3.png';

import plate1 from '@assets/img/plate-1.png';
import plate2 from '@assets/img/plate-2.png';

import buttonfriend from '@assets/img/button-friend.png';
import buttonlist from '@assets/img/button-list.png';
import buttonraking from '@assets/img/button-raking.png';
import { useTelegram } from "@hooks/contexts/telegram-context";

const eggs = [egg1, egg2, egg3];

export function Landing() {

    const { webApp, user } = useTelegram();

    const {
        actions: { pushToastify }
    } = ZuToastifyStore();

    const [visibleEgg, setVisibleEgg] = useState(0);
    const location = useLocation();

    const [days, setDays] = useState<string>('');
    const [hours, setHours] = useState<string>('');

    const ref = new URLSearchParams(location.search).get('ref');

    // const audioAmbience = new Audio(ambience);

    // useEffect(() => {

    //     if (audioAmbience?.play) {
    //         const handleEnded = () => {
    //             audioAmbience.currentTime = 0;
    //             audioAmbience.play();
    //         };

    //         audioAmbience.addEventListener('ended', handleEnded);
    //         audioAmbience.play();

    //         return () => {
    //             audioAmbience.removeEventListener('ended', handleEnded);
    //         };
    //     }
    // }, [audioAmbience])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibleEgg(prev => (prev + 1) % eggs.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibleEgg(prev => (prev + 1) % eggs.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (user) {            
            pushToastify({
                message: `Bem vindo, ${user.first_name}`,
                type: 'success',
                theme: 'dark',
            });
        }
    }, [user, ref]);

    useEffect(() => {
        const targetDate = new Date("2024-09-01T23:59:59");

        const updateCountdown = () => {
            const now = new Date();
            const timeDifference = targetDate.getTime() - now.getTime();

            if (timeDifference > 0) {
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                setDays(`${days}`);
                setHours(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
            }
        };

        const timer = setInterval(updateCountdown, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <>
            <LeafAnimation />

            <div className="eggmon-logo text-center">
                <img src={EggmonLogo} className="img-fluid" alt="EggMon" />
            </div>

            <div className="background full-height"></div>

            <div className="background-tree">
                {eggs.map((image, index) => (
                    <div
                        key={index}
                        className={`egg ${index === visibleEgg ? 'visible' : ''}`}
                        style={{ backgroundImage: `url(${image})` }}
                    />
                ))}
            </div>

            <div className="main">

                <div className="plates">
                    <div className="plate-1">
                        <div className="days">
                            {days ? `${days} DIAS` : '0 DIAS'}
                        </div>
                        <img src={plate1} alt="" className="img-fluid" />
                    </div>
                    <div className="plate-2">
                        <div className="hours">
                            {hours ? `${hours}` : '00:00:00'}
                        </div>
                        <img src={plate2} alt="" className="img-fluid" />
                    </div>
                </div>

            </div>


            <div className="actions">
                <div className="d-flex justify-content-between">
                    <div className="text-center">
                        <button type="button"><img src={buttonraking} alt="" className="img-fluid" /></button>
                    </div>
                    <div className="text-center">
                        <button type="button"><img src={buttonfriend} alt="" className="img-fluid" /></button>
                    </div>
                    <div className="text-center">
                        <button type="button"><img src={buttonlist} alt="" className="img-fluid" /></button>
                    </div>
                </div>
                <div className="text">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p>© 2024 Eggmon. Todos os direitos reservados. Qualquer uso não autorizado, incluindo, mas não se limitando a, reprodução, distribuição, exibição pública ou criação de obras derivadas com base neste material, sem a permissão expressa por escrito da Eggmon, é estritamente proibido.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Landing;
