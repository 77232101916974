import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Landing } from '@pages/public/Landing';

export default function AppRoutes() {

    const navigate = useNavigate();
    const location = useLocation();

    function changeHash(newHash: string) {
        navigate({
            ...location,
            hash: newHash,
        });
    }

    return (
        <Routes>
            <Route path="/" element={<Landing />} />
        </Routes>
    );
}
