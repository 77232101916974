/* eslint-disable no-unused-vars */
import {
    ReactNode,
    createContext,
    useContext,
} from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

interface ModalContextData {
    changeHash: (hash: string) => void
}

interface ModalProviderProps {
    children: ReactNode
}

export const ModalContext = createContext({} as ModalContextData)

export function ModalProvider({ children }: ModalProviderProps) {

    const navigate = useNavigate()
    const location = useLocation()

    function changeHash(newHash: string) {
        navigate({
            ...location,
            hash: newHash,
        });
    }

    return (
        <ModalContext.Provider
            value={{
                changeHash
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

export const useModalContext = () => useContext(ModalContext)
